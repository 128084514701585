import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  ISOLATED_EDITABLE_ROW_PLAN_TRESORERIE,
  SaisieLabel_PlanTresorerie,
  SectionFinanciere_PlanTresorerie,
} from '../../../components/projet-consortium-donnees-financieres/projet-consortium-df-plan-tresorerie/plan-tresorerie.model';
import { DEFAULT_BILANS } from '../../../components/projet-consortium-donnees-financieres/projet-consortium-df-situation-financiere/situation-financiere.model';
import {
  SaisieLabel_ComptesResultats,
  SectionFinanciere_ComptesResultats,
} from '../../../components/projet-consortium-donnees-financieres/projet-consortium-df-comptes-resultats/comptes-resultats.model';
import {
  SaisieLabel_PlanFinancement as SLPF,
  SectionFinanciere_PlanFinancement as SFPF,
} from '../../../components/projet-consortium-donnees-financieres/projet-consortium-df-plan-financement/plan-financement.model';
import { DonneesFinancieres, Status, TableDataRecord, WarningAmountsByPeriod } from '../../models/donnees-financieres.model';
import { PeriodeFinanciere } from '../../models/periode-financiere.model';
import { IsolatedEditableRow_ComptesResultats } from '../../../components/dynamic-table/dynamic-table.model';
import { SituationFinanciere } from '../../models/situation-financiere.model';

@Injectable({
  providedIn: 'root',
})
export class DonneesFinancieresHelperService {
  private readonly NB_OF_PERIODS_COMPTES_RESULTATS = 5;
  private readonly NB_OF_PERIODS_PLAN_FINANCEMENT = 5;
  private readonly NB_OF_PERIODS_PLAN_TRESORERIE = 4;
  private readonly CURRENT_YEAR_LABEL = 'Exercice en cours';
  private readonly PLAN_TRESORERIE_PERIODS: string[] = ["Aujourd'hui à 6 mois", '6 à 12 mois', '12 à 18 mois', '18 à 24 mois'];
  private readonly DEFAULT_SITUATION_FINANCIERE: SituationFinanciere = {
    commentaire: '',
    bilans: Object.values(DEFAULT_BILANS).map((bilan: { label: string; reference: string }) => ({
      label: bilan.label,
      ligneBilan: bilan.reference,
      montant: undefined,
    })),
  };

  private readonly DONNEES_FINANCIERES_DEFAULT: DonneesFinancieres = {
    statut: Status.NON_VALIDE,
    isPME: false,
    isOlderThan8Years: false,
    comptesResultats: this.buildDefaultComptesResultats(),
    planFinancements: this.buildDefaultPlansFinancement(),
    planTresoreries: this.buildDefaultPlansTresorerie(),
    situationFinanciere: this.DEFAULT_SITUATION_FINANCIERE,
  };

  private warningAmountsByPeriod: WarningAmountsByPeriod = {};

  public buildDonneesFinancieres(df: DonneesFinancieres | undefined): DonneesFinancieres {
    const donneesFinancieres: DonneesFinancieres = df || this.DONNEES_FINANCIERES_DEFAULT;
    donneesFinancieres.comptesResultats = this.buildComptesResultats(donneesFinancieres);
    donneesFinancieres.planFinancements = this.buildPlanFinancement(donneesFinancieres);
    donneesFinancieres.planTresoreries = this.buildPlanTresorerie(donneesFinancieres);
    donneesFinancieres.situationFinanciere = this.buildSituationFinanciere(donneesFinancieres);
    return donneesFinancieres;
  }

  private buildDefaultComptesResultats(): PeriodeFinanciere[] {
    const periodesFinancieres: PeriodeFinanciere[] = [];
    for (let i = 0; i < this.NB_OF_PERIODS_COMPTES_RESULTATS; i++) {
      periodesFinancieres.push({
        periode: '',
        sections: [
          {
            label: SectionFinanciere_ComptesResultats.TOTAL_PEX,
            saisies: [
              {
                label: SaisieLabel_ComptesResultats.CA,
              },
              {
                label: SaisieLabel_ComptesResultats.PI,
              },
              {
                label: SaisieLabel_ComptesResultats.PST,
              },
            ],
          },
          {
            label: SectionFinanciere_ComptesResultats.TOTAL_CPT,
            saisies: [
              {
                label: SaisieLabel_ComptesResultats.AMPM,
              },
              {
                label: SaisieLabel_ComptesResultats.VSMM,
              },
              {
                label: SaisieLabel_ComptesResultats.AACE,
              },
            ],
          },
          {
            label: SectionFinanciere_ComptesResultats.VA,
            saisies: [],
          },
          {
            label: SectionFinanciere_ComptesResultats.EBE,
            saisies: [
              {
                label: SaisieLabel_ComptesResultats.SE,
              },
              {
                label: SaisieLabel_ComptesResultats.IT,
              },
              {
                label: SaisieLabel_ComptesResultats.CP,
              },
            ],
          },
          {
            label: SectionFinanciere_ComptesResultats.RESULTAT_EXPLOITATION,
            saisies: [
              {
                label: SaisieLabel_ComptesResultats.DA,
              },
              {
                label: SaisieLabel_ComptesResultats.ACE,
              },
              {
                label: SaisieLabel_ComptesResultats.APE,
              },
            ],
          },
          {
            label: SectionFinanciere_ComptesResultats.RESULTAT_CAI,
            saisies: [
              {
                label: SaisieLabel_ComptesResultats.PF,
              },
              {
                label: SaisieLabel_ComptesResultats.CF,
              },
            ],
          },
          {
            label: SectionFinanciere_ComptesResultats.RESULTAT_EXERCICE,
            saisies: [
              {
                label: SaisieLabel_ComptesResultats.PE,
              },
              {
                label: SaisieLabel_ComptesResultats.CE,
              },
              {
                label: SaisieLabel_ComptesResultats.PSA,
              },
              {
                label: SaisieLabel_ComptesResultats.IB,
              },
            ],
          },
          {
            label: SectionFinanciere_ComptesResultats.CAPACITE_AUTOFINANCEMENT,
            saisies: [
              {
                label: SaisieLabel_ComptesResultats.EF,
              },
            ],
          },
          {
            label: IsolatedEditableRow_ComptesResultats.MPCE,
            saisies: [
              {
                label: IsolatedEditableRow_ComptesResultats.MPCE,
              },
            ],
          },
          {
            label: IsolatedEditableRow_ComptesResultats.MP,
            saisies: [
              {
                label: IsolatedEditableRow_ComptesResultats.MP,
              },
            ],
          },
        ],
      });
    }
    return periodesFinancieres;
  }

  private buildDefaultPlansFinancement(): PeriodeFinanciere[] {
    let periodesFinancieres: PeriodeFinanciere[] = [];
    for (let i = 0; i < this.NB_OF_PERIODS_PLAN_FINANCEMENT; i++) {
      periodesFinancieres = [
        ...periodesFinancieres,
        {
          periode: '',
          sections: [
            {
              label: SFPF.TOTAL_BESOINS,
              saisies: [
                {
                  label: SLPF.IDP,
                },
                {
                  label: SLPF.ILICRP,
                },
                {
                  label: SLPF.IC,
                },
                {
                  label: SLPF.BFRAUG,
                },
                {
                  label: SLPF.BFRDIM,
                },
                {
                  label: SLPF.RC,
                },
                {
                  label: SLPF.DIVERS,
                },
              ],
            },
            {
              label: SFPF.TOTAL_RESSOURCES,
              saisies: [
                {
                  label: SLPF.AC,
                },
                {
                  label: SLPF.ACC,
                },
                {
                  label: SLPF.CA,
                },
                {
                  label: SLPF.EDN,
                },
                {
                  label: SLPF.ERN,
                },
                {
                  label: SLPF.AE,
                },
              ],
            },
            {
              label: SFPF.SOLDE_TRESORERIE,
              saisies: [],
            },
            {
              label: SFPF.CUMUL_TRESORERIE,
              saisies: [],
            },
          ],
        },
      ];
    }
    return periodesFinancieres;
  }

  private buildDefaultPlansTresorerie(): PeriodeFinanciere[] {
    let periodesFinancieres: PeriodeFinanciere[] = [];
    for (let i = 0; i < this.NB_OF_PERIODS_PLAN_TRESORERIE; i++) {
      periodesFinancieres = [
        ...periodesFinancieres,
        {
          periode: '',
          sections: [
            {
              label: ISOLATED_EDITABLE_ROW_PLAN_TRESORERIE,
              saisies: [
                {
                  label: ISOLATED_EDITABLE_ROW_PLAN_TRESORERIE,
                },
              ],
            },
            {
              label: SectionFinanciere_PlanTresorerie.EDEX,
              saisies: [
                {
                  label: SaisieLabel_PlanTresorerie.EV,
                },
                {
                  label: SaisieLabel_PlanTresorerie.APE,
                },
              ],
            },
            {
              label: SectionFinanciere_PlanTresorerie.EHEX,
              saisies: [
                {
                  label: SaisieLabel_PlanTresorerie.LF,
                },
                {
                  label: SaisieLabel_PlanTresorerie.ACC,
                },
                {
                  label: SaisieLabel_PlanTresorerie.PB,
                },
                {
                  label: SaisieLabel_PlanTresorerie.FBPIPCB,
                },
                {
                  label: SaisieLabel_PlanTresorerie.FBPIAR,
                },
                {
                  label: SaisieLabel_PlanTresorerie.FBPISUB,
                },
              ],
            },
            {
              label: SectionFinanciere_PlanTresorerie.TE,
              saisies: [],
            },
            {
              label: SectionFinanciere_PlanTresorerie.DDEX,
              saisies: [
                {
                  label: SaisieLabel_PlanTresorerie.DRD,
                },
                {
                  label: SaisieLabel_PlanTresorerie.IFBPIAN,
                },
                {
                  label: SaisieLabel_PlanTresorerie.IFHBPIAN,
                },
                {
                  label: SaisieLabel_PlanTresorerie.LCBAN,
                },
                {
                  label: SaisieLabel_PlanTresorerie.ADE,
                },
              ],
            },
            {
              label: SectionFinanciere_PlanTresorerie.DHEX,
              saisies: [
                {
                  label: SaisieLabel_PlanTresorerie.ICI,
                },
                {
                  label: SaisieLabel_PlanTresorerie.RFBPIAN,
                },
                {
                  label: SaisieLabel_PlanTresorerie.RFHBPIAN,
                },
                {
                  label: SaisieLabel_PlanTresorerie.RCC,
                },
                {
                  label: SaisieLabel_PlanTresorerie.ADHE,
                },
              ],
            },
            {
              label: SectionFinanciere_PlanTresorerie.TD,
              saisies: [],
            },
            {
              label: SectionFinanciere_PlanTresorerie.STFP,
              saisies: [],
            },
            {
              label: SectionFinanciere_PlanTresorerie.SCTFP,
              saisies: [],
            },
          ],
        },
      ];
    }
    return periodesFinancieres;
  }

  private buildComptesResultats(donneesFinancieres: DonneesFinancieres): PeriodeFinanciere[] {
    const comptesResultats: PeriodeFinanciere[] = donneesFinancieres.comptesResultats?.length
      ? donneesFinancieres.comptesResultats
      : this.buildDefaultComptesResultats();
    return this.fillPeriodsAsYears(comptesResultats);
  }

  private buildPlanFinancement(donneesFinancieres: DonneesFinancieres): PeriodeFinanciere[] {
    const plansFinancements: PeriodeFinanciere[] = donneesFinancieres.planFinancements?.length
      ? donneesFinancieres.planFinancements
      : this.buildDefaultPlansFinancement();
    return this.fillPeriodsAsYears(plansFinancements);
  }

  private buildPlanTresorerie(donneesFinancieres: DonneesFinancieres): PeriodeFinanciere[] {
    const plansTresorerie: PeriodeFinanciere[] = donneesFinancieres.planTresoreries?.length
      ? donneesFinancieres.planTresoreries
      : this.buildDefaultPlansTresorerie();
    return this.fillPlanTresoreriePeriods(plansTresorerie);
  }

  private buildSituationFinanciere(donneesFinancieres: DonneesFinancieres): SituationFinanciere {
    return donneesFinancieres.situationFinanciere || this.DEFAULT_SITUATION_FINANCIERE;
  }

  private fillPeriodsAsYears(periodesFinancieres: PeriodeFinanciere[]): PeriodeFinanciere[] {
    const currentYear: number = new Date().getFullYear();
    return periodesFinancieres.map((pf: PeriodeFinanciere, index: number) => {
      return {
        ...pf,
        periode: index === 0 ? this.CURRENT_YEAR_LABEL : '' + (currentYear + index),
      };
    });
  }

  private fillPlanTresoreriePeriods(periodesFinancieres: PeriodeFinanciere[]): PeriodeFinanciere[] {
    return periodesFinancieres.map((pf: PeriodeFinanciere, index: number) => {
      return {
        ...pf,
        periode: this.PLAN_TRESORERIE_PERIODS[index],
      };
    });
  }

  public generateAddedYear(nbOfPeriodes: number): string {
    const currentYear: number = new Date().getFullYear();
    return '' + (currentYear + nbOfPeriodes);
  }

  public addComptesResultatsPeriodeFinanciere(periodesFinancieres: PeriodeFinanciere[], addedPeriod: string): PeriodeFinanciere[] {
    const added = {
      periode: addedPeriod,
      sections: [
        {
          label: SectionFinanciere_ComptesResultats.TOTAL_PEX,
          saisies: [
            {
              label: SaisieLabel_ComptesResultats.CA,
            },
            {
              label: SaisieLabel_ComptesResultats.PI,
            },
            {
              label: SaisieLabel_ComptesResultats.PST,
            },
          ],
        },
        {
          label: SectionFinanciere_ComptesResultats.TOTAL_CPT,
          saisies: [
            {
              label: SaisieLabel_ComptesResultats.AMPM,
            },
            {
              label: SaisieLabel_ComptesResultats.VSMM,
            },
            {
              label: SaisieLabel_ComptesResultats.AACE,
            },
          ],
        },
        {
          label: SectionFinanciere_ComptesResultats.VA,
          saisies: [],
        },
        {
          label: SectionFinanciere_ComptesResultats.EBE,
          saisies: [
            {
              label: SaisieLabel_ComptesResultats.SE,
            },
            {
              label: SaisieLabel_ComptesResultats.IT,
            },
            {
              label: SaisieLabel_ComptesResultats.CP,
            },
          ],
        },
        {
          label: SectionFinanciere_ComptesResultats.RESULTAT_EXPLOITATION,
          saisies: [
            {
              label: SaisieLabel_ComptesResultats.DA,
            },
            {
              label: SaisieLabel_ComptesResultats.ACE,
            },
            {
              label: SaisieLabel_ComptesResultats.APE,
            },
          ],
        },
        {
          label: SectionFinanciere_ComptesResultats.RESULTAT_CAI,
          saisies: [
            {
              label: SaisieLabel_ComptesResultats.PF,
            },
            {
              label: SaisieLabel_ComptesResultats.CF,
            },
          ],
        },
        {
          label: SectionFinanciere_ComptesResultats.RESULTAT_EXERCICE,
          saisies: [
            {
              label: SaisieLabel_ComptesResultats.PE,
            },
            {
              label: SaisieLabel_ComptesResultats.CE,
            },
            {
              label: SaisieLabel_ComptesResultats.PSA,
            },
            {
              label: SaisieLabel_ComptesResultats.IB,
            },
          ],
        },
        {
          label: SectionFinanciere_ComptesResultats.CAPACITE_AUTOFINANCEMENT,
          saisies: [
            {
              label: SaisieLabel_ComptesResultats.EF,
            },
          ],
        },
        {
          label: IsolatedEditableRow_ComptesResultats.MPCE,
          saisies: [
            {
              label: IsolatedEditableRow_ComptesResultats.MPCE,
            },
          ],
        },
        {
          label: IsolatedEditableRow_ComptesResultats.MP,
          saisies: [
            {
              label: IsolatedEditableRow_ComptesResultats.MP,
            },
          ],
        },
      ],
    };
    return [...periodesFinancieres, added];
  }

  public addPlansFinancementPeriodeFinanciere(periodesFinancieres: PeriodeFinanciere[], addedPeriod: string): PeriodeFinanciere[] {
    const added = {
      periode: addedPeriod,
      sections: [
        {
          label: SFPF.TOTAL_BESOINS,
          saisies: [
            {
              label: SLPF.IDP,
            },
            {
              label: SLPF.ILICRP,
            },
            {
              label: SLPF.IC,
            },
            {
              label: SLPF.BFRAUG,
            },
            {
              label: SLPF.BFRDIM,
            },
            {
              label: SLPF.RC,
            },
            {
              label: SLPF.DIVERS,
            },
          ],
        },
        {
          label: SFPF.TOTAL_RESSOURCES,
          saisies: [
            {
              label: SLPF.AC,
            },
            {
              label: SLPF.ACC,
            },
            {
              label: SLPF.CA,
            },
            {
              label: SLPF.EDN,
            },
            {
              label: SLPF.ERN,
            },
            {
              label: SLPF.AE,
            },
          ],
        },
        {
          label: SFPF.SOLDE_TRESORERIE,
          saisies: [],
        },
        {
          label: SFPF.CUMUL_TRESORERIE,
          saisies: [],
        },
      ],
    };
    return [...periodesFinancieres, added];
  }

  public completeWarningAmountsByPeriod_ComptesResultats(valueBySaisieBySectionByPeriod: TableDataRecord): void {
    const warningAmountsByPeriod: WarningAmountsByPeriod = this.getWarningAmountsByPeriod();
    Object.entries(valueBySaisieBySectionByPeriod).forEach(([periode, valueBySaisieBySection]) => {
      const mp: number = valueBySaisieBySection[IsolatedEditableRow_ComptesResultats.MP][IsolatedEditableRow_ComptesResultats.MP] || 0;
      const mpce: number =
        valueBySaisieBySection[IsolatedEditableRow_ComptesResultats.MPCE][IsolatedEditableRow_ComptesResultats.MPCE] || 0;
      if (!warningAmountsByPeriod[periode]) {
        warningAmountsByPeriod[periode] = {
          idpSaisie$: new BehaviorSubject<number>(0),
          mpSaisie$: new BehaviorSubject<number>(0),
          mpceSaisie$: new BehaviorSubject<number>(0),
        };
      }
      warningAmountsByPeriod[periode].mpSaisie$.next(mp);
      warningAmountsByPeriod[periode].mpceSaisie$.next(mpce);
    });
    this.setWarningAmountsByPeriod(warningAmountsByPeriod);
  }

  public completeWarningAmountsByPeriod_PlanFinancement(valueBySaisieBySectionByPeriod: TableDataRecord): void {
    const warningAmountsByPeriod: WarningAmountsByPeriod = this.getWarningAmountsByPeriod();
    Object.entries(valueBySaisieBySectionByPeriod).forEach(([periode, valueBySaisieBySection]) => {
      const idp: number = valueBySaisieBySection[SFPF.TOTAL_BESOINS][SLPF.IDP] || 0;
      if (!warningAmountsByPeriod[periode]) {
        warningAmountsByPeriod[periode] = {
          idpSaisie$: new BehaviorSubject<number>(0),
          mpSaisie$: new BehaviorSubject<number>(0),
          mpceSaisie$: new BehaviorSubject<number>(0),
        };
      }
      warningAmountsByPeriod[periode].idpSaisie$.next(idp);
    });
    this.setWarningAmountsByPeriod(warningAmountsByPeriod);
  }

  public setWarningAmountsByPeriod(warningAmountsByPeriod: WarningAmountsByPeriod): void {
    this.warningAmountsByPeriod = warningAmountsByPeriod;
  }

  public addWarningAmountsByPeriod(period: string): void {
    const existing = this.warningAmountsByPeriod[period];
    this.warningAmountsByPeriod = {
      ...this.warningAmountsByPeriod,
      [period]: {
        idpSaisie$: existing?.idpSaisie$ || new BehaviorSubject<number>(0),
        mpSaisie$: existing?.mpSaisie$ || new BehaviorSubject<number>(0),
        mpceSaisie$: existing?.mpceSaisie$ || new BehaviorSubject<number>(0),
      },
    };
  }

  public clearMpMpceSaisiesWarningAmountsByPeriod(period: string): void {
    const existing = this.warningAmountsByPeriod[period];
    this.warningAmountsByPeriod = {
      ...this.warningAmountsByPeriod,
      [period]: {
        idpSaisie$: existing?.idpSaisie$ || new BehaviorSubject<number>(0),
        mpSaisie$: new BehaviorSubject<number>(0),
        mpceSaisie$: new BehaviorSubject<number>(0),
      },
    };
  }

  public clearIdpSaisieWarningAmountsByPeriod(period: string): void {
    const existing = this.warningAmountsByPeriod[period];
    this.warningAmountsByPeriod = {
      ...this.warningAmountsByPeriod,
      [period]: {
        idpSaisie$: new BehaviorSubject<number>(0),
        mpSaisie$: existing?.mpSaisie$ || new BehaviorSubject<number>(0),
        mpceSaisie$: existing?.mpceSaisie$ || new BehaviorSubject<number>(0),
      },
    };
  }

  public getWarningAmountsByPeriod(): WarningAmountsByPeriod {
    return this.warningAmountsByPeriod;
  }
}
