import { PeriodeFinanciere } from './periode-financiere.model';
import { SituationFinanciere } from './situation-financiere.model';
import { BehaviorSubject } from 'rxjs';

export interface DonneesFinancieres {
  isPME?: boolean;
  isOlderThan8Years?: boolean;
  statut?: Status;
  comptesResultats?: PeriodeFinanciere[];
  planFinancements?: PeriodeFinanciere[];
  planTresoreries?: PeriodeFinanciere[];
  situationFinanciere?: SituationFinanciere;
}

export type ValueBySaisie = Record<string, number>;
export type ValueBySaisieBySection = Record<string, ValueBySaisie>;
export type TableDataRecord = Record<string, ValueBySaisieBySection>;

export interface WarningAmounts {
  idpSaisie$: BehaviorSubject<number>;
  mpSaisie$: BehaviorSubject<number>;
  mpceSaisie$: BehaviorSubject<number>;
}

export type WarningAmountsByPeriod = Record<string, WarningAmounts>;

export enum Status {
  VALIDE = 'VALIDE',
  INVALIDE = 'INVALIDE',
  NON_VALIDE = 'NON_VALIDE',
}
