import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-situation-conclusion',
  templateUrl: './situation-conclusion.component.html',
  styleUrl: './situation-conclusion.component.scss',
})
export class SituationConclusionComponent {
  @Input()
  public isEvaluateur = false;
  @Input()
  public isPme = false;

  isFirmInDifficulty = false;
  @Input() set isInDifficulty(inDifficulty: boolean) {
    this.isFirmInDifficulty = inDifficulty;
    if (inDifficulty) {
      this.isInDifficultyMessage = "L'ENTREPRISE EST EN DIFFICULTÉ";
    } else {
      this.isInDifficultyMessage = this.isPme
        ? "L'entreprise n'est pas en difficulté"
        : "L'entreprise n'est pas en difficulté, vérifiez les critères suivants";
    }
  }

  @Input()
  public comment = '';
  @Output()
  commentEmitter = new EventEmitter<string>();

  protected readonly NOT_PME_NOT_IN_DIFFICULTY_CHECKING_MESSAGE = `
Selon la définition européenne (ETI ou grande entreprise), deux critères supplémentaires entraînent la qualification d’entreprise en difficulté lorsqu’au titre de deux exercices consécutifs :

   - Le ratio emprunts/capitaux propres de l’entreprise est supérieur à 7,5
   - Le ratio de couverture des intérêts sur emprunts et dettes financières de l'entreprise, calculé sur la base de l'EBITDA, est inférieur à 1.0

Le respect des critères financiers ci-dessus s’apprécie uniquement sur la base des comptes sociaux de l’entreprise et non pas sur les comptes consolidés, en cas d’appartenance à un groupe.
`;
  protected isInDifficultyMessage = '';
  protected readonly maxLength = 2500;

  protected onComment($event: Event) {
    this.comment = ($event.target as HTMLInputElement)?.value ?? '';
    this.commentEmitter.emit(this.comment);
  }
}
