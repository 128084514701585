import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DocumentFileManagerService, DocumentProjet, EnumScope, EnumTypeDocument } from '@shared-ui';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Environment, SITE_ADMIN } from '../models/environment.model';

@Injectable()
export class DocumentService {
  private readonly _documentUrl;
  private readonly _documentAdminUrl;

  constructor(
    @Inject('environment') private environment: Environment,
    public httpClient: HttpClient,
    private documentFileManagerService: DocumentFileManagerService
  ) {
    this._documentUrl = this.environment.apiUrl + 'documents';
    this._documentAdminUrl = this.environment.apiAdminUrl + 'documents';
  }

  getDocuments(
    projetId: string,
    typesDocuments: EnumTypeDocument[],
    structureId?: string,
    isAdmin: boolean = false,
    etapeCleId?: string,
    scopes?: EnumScope[]
  ): Observable<DocumentProjet[]> {
    scopes = scopes ?? structureId ? [EnumScope.STRUCTURE, EnumScope.STRUCTURE_ACTIONARIAL] : [EnumScope.PROJET];
    return this.httpClient.get<DocumentProjet[]>(isAdmin ? this._documentAdminUrl : this._documentUrl, {
      params: this.buildHttpParamsForGetDocuments(projetId, typesDocuments, scopes, structureId, etapeCleId),
    });
  }

  getDocumentsByIds(projetId: string, ids: string[], structureId?: string): Observable<DocumentProjet[]> {
    let params = new HttpParams().set('projetIds', projetId);
    if (structureId) {
      params = params.append('structureId', structureId);
    }
    if (ids && ids.length > 0) {
      params = params.appendAll({ ids });
    }
    return this.httpClient.get<DocumentProjet[]>(this.environment.site === SITE_ADMIN ? this._documentAdminUrl : this._documentUrl, {
      params: params,
    });
  }

  deleteDocument(idProjet: string, idDocument: string, poleId?: string): Observable<object> {
    let queryPole = '';
    if (poleId) {
      queryPole = '&poleId=' + poleId;
    }
    return this.httpClient.delete(this._documentUrl + '/' + idDocument + '?projetId=' + idProjet + queryPole, { observe: 'response' });
  }

  createDocument(document: DocumentProjet): Observable<HttpResponse<DocumentProjet>> {
    return this.httpClient.post<DocumentProjet>(this._documentUrl, JSON.stringify(document), {
      observe: 'response',
    });
  }

  updateDocument(document: DocumentProjet): Observable<DocumentProjet> {
    return this.httpClient.put<DocumentProjet>(`${this._documentAdminUrl}/${document.id}`, document);
  }

  delete(document: DocumentProjet): Observable<void> {
    return this.deleteDocument(document.projetId, document.id, document.poleId).pipe(
      switchMap(() => this.documentFileManagerService.deleteDocument(document))
    );
  }

  uploadDocument(documentProjet: DocumentProjet, file?: File): Observable<DocumentProjet> {
    return this.createDocument(documentProjet).pipe(
      switchMap((response: HttpResponse<DocumentProjet>) => {
        if (!response.body) {
          return throwError(() => new Error('Erreur lors de la création du document'));
        }
        const documentUpserted = response.body;
        if (!file) {
          return of(documentUpserted);
        }
        return this.documentFileManagerService.uploadDocumentToS3(documentUpserted, file).pipe(
          catchError((err: HttpErrorResponse) => {
            if (documentUpserted.id) {
              this.deleteDocument(documentUpserted.projetId, documentUpserted.id, documentUpserted.poleId).subscribe();
            }
            return throwError(() => err);
          }),
          map(() => documentUpserted)
        );
      })
    );
  }

  private buildHttpParamsForGetDocuments(
    projetId: string,
    typesDocuments: EnumTypeDocument[],
    scopes: EnumScope[],
    structureId?: string,
    etapeCleId?: string
  ): HttpParams {
    let params = new HttpParams();
    params = params.append('projetIds', projetId);
    params = params.appendAll({ scopes });
    if (structureId) {
      params = params.append('structureId', structureId);
    }
    if (typesDocuments && typesDocuments.length > 0) {
      params = params.appendAll({ typesDocuments });
    }
    if (etapeCleId) {
      params = params.append('etapeCleId', etapeCleId);
    }
    return params;
  }

  getSignedDocuments(structureId: string): Observable<DocumentProjet[]> {
    return this.httpClient.get<DocumentProjet[]>(
      `${this.environment.site === SITE_ADMIN ? this._documentAdminUrl : this._documentUrl}/signes`,
      {
        params: {
          structureId: structureId,
        },
      }
    );
  }
}
