import { EnumTypeBailleurDeFonds } from '@shared-ui';
import { EnumAapStatut } from '../enums/enum.aapStatut';
import { EnumNotationTypeNote } from '../enums/enumNotationTypeNote';
import { EnumTypeWorkflow } from '../enums/enumTypeWorkflow';
import { AapInterface } from '../interfaces/aap.interface';
import { CritereReferentielAap } from './critere-referentiel-aap.model';
import { DateAutres } from './dateAutres.model';
import { DocumentAapModel } from './document-aap.model';
import { Domaine } from './domaine.model';
import { FicheDemandeAidePresent } from './ficheDemandeAidePresent.model';
import { Kpi } from './kpi.model';
import { OrganismeModel } from './organisme.model';
import { Pole } from './pole.model';
import { Program } from './program.model';
import { Projet } from './projet.model';
import { Secteur } from './secteur.model';
import { Thematique } from './thematique.model';
import { offreCommerciale } from './offre-commerciale/offre-commerciale.model';

export class Aap implements AapInterface {
  id: string;
  codeFront: string;
  code: string;
  offreCommerciale: offreCommerciale;
  codeComptable: string;
  nom: string;
  categorie: string;
  dispositif: string;
  dispositifsSecondaires: string[] = [];
  niveauInstruction: string;
  greAgre: boolean;
  ilab: boolean;
  immersion: boolean;
  personnePhysique: boolean;
  description: string;
  programme: Program = new Program();
  notification: boolean;
  logo: string;
  bal: string;
  lienDocumentation: string;
  gedConfiguree: boolean;
  statut: EnumAapStatut;
  qualificationAffectation: object[];
  historiqueStatuts: object[];
  modeAap: string;
  dateAutres: DateAutres[];
  partenaire: any = { type: null, obligatoire: null };
  documentAaps: DocumentAapModel[] = [];
  poleObligatoire = false;
  polePresent = false;
  poles: Pole[];
  datePublicationJorf: Date;
  dateOuverture: Date;
  dateFermeture: Date;
  dateCreation: Date;
  dateModification: Date;
  secteurs: Array<Secteur>;
  secteursObligatoire: boolean;
  secteursPresent: boolean;
  domaines: Domaine[];
  domaineObligatoire: boolean;
  domainePresent: boolean;
  budgetEstime: boolean;
  urlTemplateDepot: string;
  thematiquesPresent: boolean;
  thematiquesObligatoire: boolean;
  thematiques: Thematique[] = [];
  notationAuditionPresent: boolean;
  notationAuditionTemplate: string;
  notationAuditionTypeNote: EnumNotationTypeNote;
  notationBarreAudition: number;
  notationEvaluationPresent: boolean;
  notationEvaluationTemplate: string;
  notationEvaluationTypeNote: EnumNotationTypeNote;
  notationBarreEvaluation: number;
  typeWKF: EnumTypeWorkflow;
  enqueteProjetPresent: boolean;
  enqueteProjetObligatoire: boolean;
  equipeObligatoire: boolean;
  equipePresent: boolean;
  equipeEnqueteRPPresent: boolean;
  kpis: Kpi[] = [];
  projets: Projet[] = [];
  criteresEvaluations: CritereReferentielAap[] = [];
  indicateursEligibilites: CritereReferentielAap[] = [];
  organismes: OrganismeModel[] = [];
  contractualisationPresent: boolean;
  instructionPresent: boolean;
  eligibilitePresent: boolean;
  interventionPiObligatoire: boolean;
  notationInstructionTypeNote: EnumNotationTypeNote;
  depensesProjetPresent: boolean;
  ficheComPresent: boolean;
  ficheDemandeAidePresent: FicheDemandeAidePresent;
  bailleurDeFonds: EnumTypeBailleurDeFonds;
  transferToGedStatus: string;
}
