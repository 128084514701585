<mat-expansion-panel
  [expanded]="openExpertAccordion"
  (opened)="isPanelOpen = true"
  (closed)="isPanelOpen = false"
  [ngClass]="{ 'overflow-visible': isPanelOpen && showOverflow }"
>
  <mat-expansion-panel-header [ngClass]="{ 'header-with-subtitle': subTitle, 'disable-hover': disableHover }">
    <mat-panel-title>
      <lib-pxl-icon class="green-checkmark" icon="icon-action-check-circle" aria-hidden="true" *ngIf="showValidIcon"></lib-pxl-icon>
      <div class="d-flex flex-wrap">
        <span class="w-100"
          ><b class="title">{{ title }}</b> {{ titleComplement }}<lib-asterisk *ngIf="showMandatoryAsterisk"></lib-asterisk
        ></span>
        <span class="subtitle" *ngIf="subTitle">{{ subTitle }}</span>
        <ng-content select="[panel-header-complement]"></ng-content>
      </div>
      <button
        *ngIf="actionButtonLabel"
        class="action-button ds-btn ds-btn--tertiary"
        (click)="actionButtonClicked(); $event.stopPropagation()"
      >
        {{ actionButtonLabel }}
      </button>
      <div *ngIf="badge" matBadge="{{ badge.text }}" [matBadgeSize]="badge.size" [matBadgeColor]="badge.color" class="ps-4"></div>
      <ng-content select="[panel-header-action-button]"> </ng-content>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <ng-content select="[panel-content]"></ng-content>
</mat-expansion-panel>
