import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

import {
  CancelSaveValidateModifyButtonsComponent,
  CardInfoComponent,
  CardTextComponent,
  CardTitleComponent,
  ChangeDocumentModalComponent,
  CommentaireModalComponent,
  ConfirmModalComponent,
  DemandeRectificationCardComponent,
  DocumentListComponent,
  DsMultiselectComponent,
  DynamicTableComponent,
  FicheDemandeAideActionnairesComponent,
  FicheDemandeAideListeAidesComponent,
  FicheDemandeAideSignatureElectroniqueComponent,
  FicheDemandeAideSignatureIdentiteFormComponent,
  FicheDemandeAideSignatureManuelleComponent,
  GrilleImpactComponent,
  InformationSiegeComponent,
  InformationContactComponent,
  KpiQuestionsFormFieldComponent,
  NotationAvisItemComponent,
  ProjetConsortiumDfComptesResultatsComponent,
  ProjetConsortiumDonneesFinancieresComponent,
  ProjetInfosGeneralesComponent,
  StepperComponent,
  StructureAdresseComponent,
  TextModalComponent,
  ToggleComponent,
  TableDonneesFinancieresEtPeComponent,
  TextSimpleInputModalComponent,
} from './components/_public_api';
import { FicheComInfoProjetComponent } from './components/fiche-com/fiche-com-info-projet/fiche-com-info-projet.component';
import { FicheComInfoChefDeFileComponent } from './components/fiche-com/fiche-com-info-chef-de-file/fiche-com-info-chef-de-file.component';
import { FicheComLogosStructuresComponent } from './components/fiche-com/fiche-com-logos-structures/fiche-com-logos-structures.component';
import { FicheComContactPresseComponent } from './components/fiche-com/fiche-com-contact-presse/fiche-com-contact-presse.component';
import { FicheComDemandeDerogationComponent } from './components/fiche-com/fiche-com-demande-derogation/fiche-com-demande-derogation.component';
import { IconComponent } from './shared/components/_public_shared';
import { MaterialModule } from './shared/modules/material.module';
import { ProjetValidationFunction } from './utils/projet-validation';
import { StructureValidationFunction } from './utils/structure-validation';
import { RadioButtonsComponent } from './components/radio-buttons/radio-buttons.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { RouterOutlet } from '@angular/router';
import { AsteriskComponent } from './components/asterisk/asterisk.component';
import { TextBoxComponent } from './components/text-box/text-box.component';
import { ExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component';
import { SelectUploadDocComponent } from './components/select-upload-doc/select-upload-doc.component';
import { UploadFilesComponent } from './components/upload-files/upload-files.component';
import { ButtonUploadFilesComponent } from './components/button_upload-files/button-upload-files.component';
import { SelectDocumentModalComponent } from './components/select-document-modal/select-document-modal.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { StructureRoleSelectComponent } from './components/structure-role-select/structure-role-select.component';
import { StructureSearcherComponent } from './components/structure-searcher/structure-searcher.component';
import { SiretDirective } from './shared/directives/siret.directive';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { DemandeRectificationModalComponent } from './components/structure-adresse/demande-rectification-modal/demande-rectification-modal.component';
import { DemandeModificationContainerComponent } from './components/demande-modification/demande-modification-container/demande-modification-container.component';
import { DemandeModificationAdminComponent } from './components/demande-modification/demande-modification-admin/demande-modification-admin.component';
import { UploadDocumentHttpService } from './shared/services/upload-document.http.service';
import { StructureSyntheseHelperService } from './shared/services/structure-synthese-helper.service';
import { DemandeModificationCandidatComponent } from './components/demande-modification/demande-modification-candidat/demande-modification-candidat.component';
import { DemandeDocumentComplementaireContainerComponent } from './components/demande-document-complementaire/demande-document-complementaire-container/demande-document-complementaire-container.component';
import { DemandeDocumentComplementaireAdminComponent } from './components/demande-document-complementaire/demande-document-complementaire-admin/demande-document-complementaire-admin.component';
import { DemandeDocumentComplementaireCandidatComponent } from './components/demande-document-complementaire/demande-document-complementaire-candidat/demande-document-complementaire-candidat.component';
import { DocumentCardComponent } from './components/document-card/document-card.component';
import { DocumentService } from './shared/services/document.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DocumentLightComponent } from './components/document-light/document-light.component';
import { NoCountrySuggestionWithoutHintDirective } from './shared/directives/no-country-suggestion-without-hint.directive';
import { SharedFunction } from './utils/sharedFunction';
import { DownloadUtils } from './utils/download-utils';
import { RibIbanCardComponent } from './components/information-bancaire/rib-iban-card/rib-iban-card.component';
import { IbanDirective } from './shared/directives/iban.directive';
import { IbanPipe } from './shared/pipes/iban.pipe';
import { TableComponent } from './components/depense-projet/table/table.component';
import { DecimalNumberDirective } from './shared/directives/decimal-number.directive';
import { AutoFocusDirective } from './shared/directives/auto-focus.directive';
import { TableSyntheseComponent } from './components/depense-projet/table-synthese/table-synthese.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { FormatNumberPipe } from './components/depense-projet/format-number.pipe';
import { DepensesProjetTableHeaderComponent } from './components/depense-projet/table-synthese/depenses-projet-table-header/depenses-projet-table-header.component';
import { SignataireFormComponent } from './components/signataires/signataire-form/signataire-form.component';
import { SignataireListComponent } from './components/signataires/signataire-list/signataire-list.component';
import { TableDialogDeleteComponent } from './components/depense-projet/table-synthese/table-dialog-delete/table-dialog-delete.component';
import { MyRemoveIfFeatureOffDirective } from './shared/directives/remove-if-feature-off.directive';
import { TableDialogDuplicateComponent } from './components/depense-projet/table-synthese/table-dialog-duplicate/table-dialog-duplicate.component';
import { DocumentsCardUploaderComponent } from './components/documents-card-uploader/documents-card-uploader.component';
import { DsProgressBarComponent } from './components/ds-progress-bar/ds-progress-bar.component';
import { TelephoneInputComponent } from './components/telephone-input/telephone-input.component';
import { DocumentFileManagerService } from './shared/services/document-file-manager.service';
import { TableDialogEditComponent } from './components/depense-projet/table-synthese/table-dialog-edit/table-dialog-edit.component';
import { OverflowTooltipDirective } from './shared/directives/overflow-tooltip.directive';
import { NgSubscribeDirective } from './shared/directives/ng-subscribe.directive';
import { SignatureContratFrameComponent } from './components/signature-contrat-frame/signature-contrat-frame.component';
import { ProjetConsortiumDfPlanFinancementComponent } from './components/projet-consortium-donnees-financieres/projet-consortium-df-plan-financement/projet-consortium-df-plan-financement.component';
import { ProjetConsortiumDfPlanTresorerieComponent } from './components/projet-consortium-donnees-financieres/projet-consortium-df-plan-tresorerie/projet-consortium-df-plan-tresorerie.component';
import { ProcedureSignatureContratHistoryComponent } from './components/procedure-signature-contrat-history/procedure-signature-contrat-history.component';
import { YearsButtonsComponent } from './components/years-buttons/years-buttons.component';
import { ProcedureSignatureSignataireStatutUpdaterComponent } from './components/procedure-signature-signataire-statut-updater/procedure-signature-signataire-statut-updater.component';
import { ContratDocumentListComponent } from './components/contractualisation/contrat-document-list/contrat-document-list.component';
import { ProjetConsortiumDfSituationFinanciereComponent } from './components/projet-consortium-donnees-financieres/projet-consortium-df-situation-financiere/projet-consortium-df-situation-financiere.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FirmInDifficultyComponent } from './components/projet-consortium-donnees-financieres/projet-consortium-df-situation-financiere/firm-in-difficulty/firm-in-difficulty.component';
import { SituationConclusionComponent } from './components/projet-consortium-donnees-financieres/projet-consortium-df-situation-financiere/situation-conclusion/situation-conclusion.component';
import { RestrictSlashBackslashDirective } from './shared/directives/restrict-slash-backslash.directive';
import { PositiveIntegerOnlyDirective } from './shared/directives/positive-integer-only.directive';
import { NumericAndComaOnlyDirective } from './shared/directives/numeric-and-coma-only.directive';
import { NumericComaDotOnlyDirective } from './shared/directives/numeric-coma-dot-only.directive';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgbPopover,
    RouterOutlet,
    MatSelectCountryModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatMenuModule,
    MatButtonToggleModule,
  ],
  declarations: [
    ProjetInfosGeneralesComponent,
    IconComponent,
    CardTextComponent,
    CardTitleComponent,
    ChangeDocumentModalComponent,
    ProjetConsortiumDonneesFinancieresComponent,
    ProjetConsortiumDfComptesResultatsComponent,
    GrilleImpactComponent,
    KpiQuestionsFormFieldComponent,
    RadioButtonsComponent,
    PageHeaderComponent,
    AsteriskComponent,
    TextBoxComponent,
    ExpansionPanelComponent,
    SelectUploadDocComponent,
    UploadFilesComponent,
    ButtonUploadFilesComponent,
    SelectDocumentModalComponent,
    CommentaireModalComponent,
    ConfirmModalComponent,
    DatePickerComponent,
    DateRangePickerComponent,
    StructureRoleSelectComponent,
    NotationAvisItemComponent,
    StructureAdresseComponent,
    InformationSiegeComponent,
    InformationContactComponent,
    StructureSearcherComponent,
    SiretDirective,
    IbanDirective,
    DemandeRectificationModalComponent,
    DemandeRectificationCardComponent,
    DemandeModificationContainerComponent,
    DemandeModificationAdminComponent,
    DemandeModificationCandidatComponent,
    DemandeDocumentComplementaireContainerComponent,
    DemandeDocumentComplementaireAdminComponent,
    DemandeDocumentComplementaireCandidatComponent,
    DocumentCardComponent,
    DocumentListComponent,
    DynamicTableComponent,
    CardInfoComponent,
    DocumentLightComponent,
    NoCountrySuggestionWithoutHintDirective,
    FicheComInfoProjetComponent,
    FicheComInfoChefDeFileComponent,
    FicheComLogosStructuresComponent,
    FicheComContactPresseComponent,
    FicheComDemandeDerogationComponent,
    FicheDemandeAideSignatureManuelleComponent,
    FicheDemandeAideSignatureElectroniqueComponent,
    FicheDemandeAideSignatureIdentiteFormComponent,
    FicheDemandeAideActionnairesComponent,
    FicheDemandeAideListeAidesComponent,
    DsMultiselectComponent,
    RibIbanCardComponent,
    IbanPipe,
    TableComponent,
    DecimalNumberDirective,
    AutoFocusDirective,
    RestrictSlashBackslashDirective,
    PositiveIntegerOnlyDirective,
    NumericAndComaOnlyDirective,
    NumericComaDotOnlyDirective,
    TableSyntheseComponent,
    FormatNumberPipe,
    DepensesProjetTableHeaderComponent,
    SignataireFormComponent,
    SignataireListComponent,
    TextModalComponent,
    TableDialogDeleteComponent,
    TableDialogDuplicateComponent,
    MyRemoveIfFeatureOffDirective,
    DocumentsCardUploaderComponent,
    DsProgressBarComponent,
    TelephoneInputComponent,
    TableDialogEditComponent,
    StepperComponent,
    ToggleComponent,
    OverflowTooltipDirective,
    TableDonneesFinancieresEtPeComponent,
    TextSimpleInputModalComponent,
    CancelSaveValidateModifyButtonsComponent,
    DynamicTableComponent,
    NgSubscribeDirective,
    ProjetConsortiumDfPlanFinancementComponent,
    SignatureContratFrameComponent,
    ProjetConsortiumDfPlanTresorerieComponent,
    ProcedureSignatureContratHistoryComponent,
    YearsButtonsComponent,
    ProcedureSignatureSignataireStatutUpdaterComponent,
    ContratDocumentListComponent,
    ProjetConsortiumDfSituationFinanciereComponent,
    FirmInDifficultyComponent,
    SituationConclusionComponent,
  ],
  exports: [
    ProjetInfosGeneralesComponent,
    IconComponent,
    CardTextComponent,
    CardTitleComponent,
    ChangeDocumentModalComponent,
    GrilleImpactComponent,
    KpiQuestionsFormFieldComponent,
    RadioButtonsComponent,
    PageHeaderComponent,
    AsteriskComponent,
    TextBoxComponent,
    ExpansionPanelComponent,
    UploadFilesComponent,
    ButtonUploadFilesComponent,
    DatePickerComponent,
    DateRangePickerComponent,
    StructureRoleSelectComponent,
    NotationAvisItemComponent,
    StructureAdresseComponent,
    InformationSiegeComponent,
    InformationContactComponent,
    StructureSearcherComponent,
    SiretDirective,
    IbanDirective,
    DemandeRectificationCardComponent,
    DemandeModificationContainerComponent,
    DemandeModificationAdminComponent,
    DemandeModificationCandidatComponent,
    DemandeDocumentComplementaireContainerComponent,
    DemandeDocumentComplementaireAdminComponent,
    DemandeDocumentComplementaireCandidatComponent,
    DocumentCardComponent,
    DocumentListComponent,
    CardInfoComponent,
    DocumentLightComponent,
    NoCountrySuggestionWithoutHintDirective,
    FicheComInfoProjetComponent,
    FicheComInfoChefDeFileComponent,
    FicheComLogosStructuresComponent,
    FicheComContactPresseComponent,
    FicheComDemandeDerogationComponent,
    FicheDemandeAideSignatureManuelleComponent,
    FicheDemandeAideSignatureElectroniqueComponent,
    FicheDemandeAideSignatureIdentiteFormComponent,
    FicheDemandeAideActionnairesComponent,
    FicheDemandeAideListeAidesComponent,
    DsMultiselectComponent,
    RibIbanCardComponent,
    IbanPipe,
    TableComponent,
    DecimalNumberDirective,
    AutoFocusDirective,
    TableSyntheseComponent,
    SignataireFormComponent,
    SignataireListComponent,
    TextModalComponent,
    MyRemoveIfFeatureOffDirective,
    DocumentsCardUploaderComponent,
    DsProgressBarComponent,
    TelephoneInputComponent,
    StepperComponent,
    ToggleComponent,
    OverflowTooltipDirective,
    TableDonneesFinancieresEtPeComponent,
    TextSimpleInputModalComponent,
    DynamicTableComponent,
    CancelSaveValidateModifyButtonsComponent,
    ProjetConsortiumDonneesFinancieresComponent,
    ProjetConsortiumDfComptesResultatsComponent,
    NgSubscribeDirective,
    SignatureContratFrameComponent,
    ProcedureSignatureContratHistoryComponent,
    ProcedureSignatureSignataireStatutUpdaterComponent,
    ContratDocumentListComponent,
    RestrictSlashBackslashDirective,
    PositiveIntegerOnlyDirective,
    NumericAndComaOnlyDirective,
    NumericComaDotOnlyDirective,
  ],
  providers: [
    ProjetValidationFunction,
    StructureValidationFunction,
    UploadDocumentHttpService,
    StructureSyntheseHelperService,
    DocumentFileManagerService,
    DocumentService,
    SharedFunction,
    DownloadUtils,
  ],
})
export class SharedUiModule {}
