import { Injectable } from '@angular/core';
import {
  EnumProcedureSignatureStatut,
  EnumStatutContractualisation,
  EnumStatutSignature,
  EnumTypeSignataire,
  ProcedureSignatureStructure,
  SyntheseContractualisationProjet,
  SyntheseContractualisationStructure,
  Utilisateur,
} from '@shared-ui';
@Injectable({
  providedIn: 'root',
})
export class StructureSyntheseHelperService {
  retrieveLastDateSignatureElectroniqueConsortium(currentProcedure: ProcedureSignatureStructure): Date | null {
    const datesSignatures = currentProcedure.signataires
      ?.filter(signataire => !!signataire.signature)
      .map(signataire => signataire.signature.dateSignature)
      .filter(dateSignature => !!dateSignature);
    if (datesSignatures?.length > 0) {
      return datesSignatures.reduce((latest, item) => (item > latest ? item : latest));
    }
    return null;
  }

  sortProcedures(procedures: ProcedureSignatureStructure[]): void {
    procedures?.sort(this.compareProcedures);
  }

  private compareProcedures(procedure1: ProcedureSignatureStructure, procedure2: ProcedureSignatureStructure): number {
    if (!procedure1.dateCreation) {
      return -1;
    } else if (!procedure2.dateCreation) {
      return 1;
    }

    return new Date(procedure1.dateCreation).getTime() - new Date(procedure2.dateCreation).getTime();
  }

  getCurrentProcedure(structureSynthese: SyntheseContractualisationStructure): ProcedureSignatureStructure | null {
    if (!structureSynthese.proceduresSignatures?.length) {
      return null;
    }

    this.sortProcedures(structureSynthese.proceduresSignatures);
    return structureSynthese.proceduresSignatures[structureSynthese.proceduresSignatures.length - 1];
  }

  getNbSignataire(synthese: SyntheseContractualisationStructure): number {
    return (
      synthese.proceduresSignatures
        ?.find(procedure => procedure.statut === EnumProcedureSignatureStatut.EN_COURS)
        ?.signataires.filter(signataire =>
          [EnumTypeSignataire.SIGNATAIRE_INTERNE, EnumTypeSignataire.SIGNATAIRE_EXTERNE, EnumTypeSignataire.SOLIDAIRE_EXTERNE].includes(
            signataire.type
          )
        )?.length || 0
    );
  }

  getNbSignatureEffectuee(synthese: SyntheseContractualisationStructure): number {
    const currentProcedureSignataires = synthese.proceduresSignatures?.find(
      procedure => procedure.statut === EnumProcedureSignatureStatut.EN_COURS
    )?.signataires;
    const allowedSignatairesType = [
      EnumTypeSignataire.SIGNATAIRE_EXTERNE,
      EnumTypeSignataire.SIGNATAIRE_INTERNE,
      EnumTypeSignataire.SOLIDAIRE_EXTERNE,
    ];
    return (
      currentProcedureSignataires?.filter(
        signataire => allowedSignatairesType.includes(signataire.type) && signataire.signature?.statut === EnumStatutSignature.TERMINEE
      )?.length || 0
    );
  }

  initSyntheseStructureCustomFields(structureSynthese: SyntheseContractualisationStructure, utilisateur?: Utilisateur): void {
    const currentProcedure = this.getCurrentProcedure(structureSynthese);
    if (currentProcedure) {
      structureSynthese.isProcedureEnCours = currentProcedure.statut === EnumProcedureSignatureStatut.EN_COURS;
      structureSynthese.isProcedureTerminee = currentProcedure.statut === EnumProcedureSignatureStatut.TERMINEE;
      structureSynthese.isUserASigner = utilisateur ? this.isUserASigner(currentProcedure, utilisateur.email) : undefined;
    }
  }

  isProcedureEnCours(synthese: SyntheseContractualisationStructure): boolean {
    return this.getCurrentProcedure(synthese)?.statut === EnumProcedureSignatureStatut.EN_COURS;
  }

  isSignataireSelectedInOngoingProcedure(scStructure: SyntheseContractualisationStructure, signataireId: string): boolean {
    const ongoingProcedure = scStructure.proceduresSignatures.find(p => p.statut === EnumProcedureSignatureStatut.EN_COURS);
    return ongoingProcedure !== undefined && ongoingProcedure.signataires.some(signataire => signataire.id === signataireId);
  }

  isProcedureSignatureLunchable(
    syntheseProjet: SyntheseContractualisationProjet,
    syntheseStructure: SyntheseContractualisationStructure
  ): boolean {
    return (
      syntheseProjet?.contractualisation?.statutEcheanceProcedureSignature === EnumStatutContractualisation.VALIDE &&
      syntheseStructure.contractualisation?.pretASigner &&
      !!syntheseStructure.contractualisation?.validationDocumentsContrat &&
      syntheseStructure.contractualisation?.statutSignataires === EnumStatutContractualisation.VALIDE
    );
  }

  private isUserASigner(currentProcedure: ProcedureSignatureStructure | null, email: string) {
    return currentProcedure?.signataires.some(signataire => signataire.email === email);
  }

  getCurrentSigner(currentProcedure: ProcedureSignatureStructure | null, utilisateur: Utilisateur, types: EnumTypeSignataire[]) {
    return currentProcedure?.signataires.find(signataire => signataire.email === utilisateur.email && types.includes(signataire.type));
  }

  getProceduresHistory(syntheseStructure: SyntheseContractualisationStructure, utilisateur: Utilisateur, types: EnumTypeSignataire[]) {
    return syntheseStructure.proceduresSignatures.filter(
      procedure =>
        (procedure.statut !== EnumProcedureSignatureStatut.EN_COURS && this.isProcedureDeniedBySigner(procedure, utilisateur, types)) ||
        this.isProcedureExpired(procedure, utilisateur, types)
    );
  }

  private isProcedureDeniedBySigner(procedure: ProcedureSignatureStructure, utilisateur: Utilisateur, types: EnumTypeSignataire[]) {
    return (
      procedure.statut === EnumProcedureSignatureStatut.REFUSEE &&
      procedure.signataires.some(
        signataire => signataire.email === utilisateur.email && types.includes(signataire.type) && signataire.signature
      )
    );
  }

  private isProcedureExpired(procedure: ProcedureSignatureStructure, utilisateur: Utilisateur, types: EnumTypeSignataire[]) {
    return (
      procedure.statut === EnumProcedureSignatureStatut.EXPIREE &&
      procedure.signataires.some(
        signataire => signataire.email === utilisateur.email && types.includes(signataire.type) && signataire.signature
      )
    );
  }

  getProcedureLink(procedure: ProcedureSignatureStructure, utilisateur: Utilisateur) {
    return procedure.signataires.find(signataire => signataire.email === utilisateur.email)?.signature?.url;
  }

  didUserDeniedSignature(procedure: ProcedureSignatureStructure, utilisateur: Utilisateur) {
    return procedure.signataires.some(
      signataire => signataire.email === utilisateur.email && signataire.signature?.statut === EnumStatutSignature.REFUSEE
    );
  }

  isUserADesignatedSigner(syntheses: SyntheseContractualisationStructure[], utilisateur: Utilisateur, types: EnumTypeSignataire[]) {
    return syntheses.some(synthese =>
      synthese.proceduresSignatures?.some(procedure =>
        procedure.signataires.some(signataire => signataire.email === utilisateur.email && types.includes(signataire.type))
      )
    );
  }

  isUserSignataireInProcedureSignatures(
    proceduresSignatures: ProcedureSignatureStructure[],
    utilisateur: Utilisateur,
    types: EnumTypeSignataire[]
  ) {
    return proceduresSignatures.some(
      procedure =>
        [
          EnumProcedureSignatureStatut.EXPIREE,
          EnumProcedureSignatureStatut.TERMINEE,
          EnumProcedureSignatureStatut.REFUSEE,
          EnumProcedureSignatureStatut.EN_COURS,
        ].includes(procedure.statut) &&
        procedure.signataires.some(
          signataire => signataire.email === utilisateur.email && types.includes(signataire.type) && signataire.signature
        )
    );
  }

  isUserSuiveurInProcedureSignatures(
    proceduresSignatures: ProcedureSignatureStructure[],
    utilisateur: Utilisateur,
    type: EnumTypeSignataire
  ) {
    return proceduresSignatures.some(
      procedure =>
        [
          EnumProcedureSignatureStatut.EXPIREE,
          EnumProcedureSignatureStatut.TERMINEE,
          EnumProcedureSignatureStatut.REFUSEE,
          EnumProcedureSignatureStatut.EN_COURS,
        ].includes(procedure.statut) &&
        procedure.signataires.some(signataire => signataire.email === utilisateur.email && type === signataire.type)
    );
  }
}
