import { Component, Input, OnInit } from '@angular/core';
import { noop } from 'rxjs';
import { TableDataRecord, ValueBySaisie } from '../../../shared/models/donnees-financieres.model';
import { DonneesFinancieresHelperService } from '../../../shared/services/donnees-financieres/donnees-financieres.helper.service';
import { SignalService } from '../../../shared/services/signal.service';
import { DynamicTableComponent, DynamicTableDataSource } from '../../dynamic-table/dynamic-table.component';
import { Cell, CellNumberValue } from '../../dynamic-table/dynamic-table.model';
import {
  ISOLATED_EDITABLE_ROW_PLAN_TRESORERIE,
  IS_ISOLATED_EDITABLE_ROW_PLAN_TRESORERIE,
  SectionFinanciere_PlanTresorerie as SFPT,
  SaisieLabel_PlanTresorerie as SLPT,
  SectionFinanciere_NameSpace_PlanTresorerie,
} from './plan-tresorerie.model';

@Component({
  selector: 'lib-projet-consortium-df-plan-tresorerie',
  templateUrl: '../../dynamic-table/dynamic-table.component.html',
  styleUrls: ['../../dynamic-table/dynamic-table.component.scss'],
})
export class ProjetConsortiumDfPlanTresorerieComponent extends DynamicTableComponent implements OnInit {
  @Input() public upTitleRow = false;

  public valueBySaisieBySectionByPeriod: TableDataRecord = {};

  private sumSaisies = (colDef: string, sfpt: SFPT, saisiesToSum: SLPT[]): number => {
    return saisiesToSum.map(saisie => this.valueBySaisieBySectionByPeriod[colDef][sfpt][saisie]).reduce((sum, current) => sum + current, 0);
  };

  public resultsCalculatorBySectionFinanciere: Record<SFPT, (colDef: string) => void> = {
    [SFPT.EDEX]: (colDef: string) => {
      // Encaissements d'exploitation
      const edex: number = this.sumSaisies(colDef, SFPT.EDEX, [SLPT.EV, SLPT.APE]);
      this.valueBySaisieBySectionByPeriod[colDef][SFPT.EDEX][SFPT.EDEX] = edex;
      this.updateDataSource(colDef, SFPT.EDEX, edex);
      this.resultsCalculatorBySectionFinanciere[SFPT.EHEX](colDef);
    },

    [SFPT.EHEX]: (colDef: string) => {
      // Encaissements hors exploitation
      const ehex: number = this.sumSaisies(colDef, SFPT.EHEX, [SLPT.LF, SLPT.ACC, SLPT.PB, SLPT.FBPIPCB, SLPT.FBPIAR, SLPT.FBPISUB]);
      this.valueBySaisieBySectionByPeriod[colDef][SFPT.EHEX][SFPT.EHEX] = ehex;
      this.updateDataSource(colDef, SFPT.EHEX, ehex);

      // Total des encaissements
      const edex: number = this.valueBySaisieBySectionByPeriod[colDef][SFPT.EDEX][SFPT.EDEX] || 0;
      const te: number = edex + ehex;
      this.valueBySaisieBySectionByPeriod[colDef][SFPT.TE] = {
        [SFPT.TE]: te,
      };
      this.updateDataSource(colDef, SFPT.TE, te);
      this.resultsCalculatorBySectionFinanciere[SFPT.DDEX](colDef);
    },

    [SFPT.TE]: () => noop(),

    [SFPT.DDEX]: (colDef: string) => {
      // Décaissements d'exploitation
      const ddex: number = this.sumSaisies(colDef, SFPT.DDEX, [SLPT.DRD, SLPT.IFBPIAN, SLPT.IFHBPIAN, SLPT.LCBAN, SLPT.ADE]);
      this.valueBySaisieBySectionByPeriod[colDef][SFPT.DDEX][SFPT.DDEX] = ddex;
      this.updateDataSource(colDef, SFPT.DDEX, ddex);
      this.resultsCalculatorBySectionFinanciere[SFPT.DHEX](colDef);
    },

    [SFPT.DHEX]: (colDef: string) => {
      // Décaissements hors exploitation
      const dhex: number = this.sumSaisies(colDef, SFPT.DHEX, [SLPT.ICI, SLPT.RFBPIAN, SLPT.RFHBPIAN, SLPT.RCC, SLPT.ADHE]);
      this.valueBySaisieBySectionByPeriod[colDef][SFPT.DHEX][SFPT.DHEX] = dhex;
      this.updateDataSource(colDef, SFPT.DHEX, dhex);

      // Total des décaissements
      const ddex: number = this.valueBySaisieBySectionByPeriod[colDef][SFPT.DDEX][SFPT.DDEX] || 0;
      const td: number = ddex + dhex;
      this.valueBySaisieBySectionByPeriod[colDef][SFPT.TD] = {
        [SFPT.TD]: td,
      };
      this.updateDataSource(colDef, SFPT.TD, td);

      // Solde de trésorerie en fin de période
      const te: number = this.valueBySaisieBySectionByPeriod[colDef][SFPT.TE][SFPT.TE] || 0;
      const stfp: number = te + td;
      this.valueBySaisieBySectionByPeriod[colDef][SFPT.STFP] = {
        [SFPT.STFP]: stfp,
      };
      this.updateDataSource(colDef, SFPT.STFP, stfp);

      // Solde cumulé de trésorerie en fin de période
      const valueBySaisie: ValueBySaisie = this.valueBySaisieBySectionByPeriod[colDef][ISOLATED_EDITABLE_ROW_PLAN_TRESORERIE];
      const tdp: number = (valueBySaisie && valueBySaisie[ISOLATED_EDITABLE_ROW_PLAN_TRESORERIE]) || 0;
      const sctfp: number = tdp + stfp;
      this.valueBySaisieBySectionByPeriod[colDef][SFPT.SCTFP] = {
        [SFPT.SCTFP]: sctfp,
      };
      this.updateDataSource(colDef, SFPT.SCTFP, sctfp);
    },

    [SFPT.TD]: () => noop(),
    [SFPT.STFP]: () => noop(),
    [SFPT.SCTFP]: () => noop(),
  };

  constructor(protected override service: DonneesFinancieresHelperService, private signalService: SignalService) {
    super(service);
  }

  ngOnInit(): void {
    // init plansTresorerie
    this.signalService.setPlansTresorerie(this.periodesFinancieres);
    this.buildColumns();
    this.rows = this.buildRows(this.upTitleRow, IS_ISOLATED_EDITABLE_ROW_PLAN_TRESORERIE);
    this.dataSource = new DynamicTableDataSource(this.rows);
    this.valueBySaisieBySectionByPeriod = this.initDataRecord();
    this.initTableCalculation(this.periodes);
  }

  private initTableCalculation(periodes: string[]) {
    periodes.forEach((periode: string) => {
      this.resultsCalculatorBySectionFinanciere[SFPT.EDEX](periode);
    });
  }

  public override onDataChange = (event: Event, cell: Cell): void => {
    const newValue: string | number | undefined = (event?.target as HTMLInputElement)?.value ?? '';
    const { colDef, sectionLabel, rowDef } = cell;
    const cellNumberValue = new CellNumberValue(newValue).value;
    this.valueBySaisieBySectionByPeriod[colDef][sectionLabel][rowDef] = cellNumberValue;
    this.signalService.updatePlansTresorerie(colDef, sectionLabel, rowDef, cellNumberValue);
    if (sectionLabel === ISOLATED_EDITABLE_ROW_PLAN_TRESORERIE) {
      this.resultsCalculatorBySectionFinanciere[SFPT.DHEX](colDef);
    } else {
      const sectionFinanciereLabel: SFPT | undefined = SectionFinanciere_NameSpace_PlanTresorerie.toEnum(sectionLabel);
      if (sectionFinanciereLabel) {
        this.resultsCalculatorBySectionFinanciere[sectionFinanciereLabel](colDef);
      }
    }
  };
}
